/* FOR EDIT EXAM DROP DOWNS */

li button svg {
  height: 0.9rem !important;
}

li button {
  font-size: 0.9rem !important;
  font-weight: 500 !important;
}

li ul li a svg{
  height: 0.9rem !important;
}


/* END FOR EDIT EXAM DROP DOWNS */

.cursor-pointer {
  cursor: pointer
}